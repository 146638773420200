import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/AutoStories.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/Bookmark.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/Chat.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/Code.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/CorporateFare.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/Dashboard.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/FolderCopy.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/Help.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/Key.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/Person.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/Rocket.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/Settings.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/icons-material/esm/Storage.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/@mui/material/Button/Button.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/public/assets/404_Illustration.png");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/public/assets/illustrations/logos/small/training_aas.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/public/assets/illustrations/logos/small/vlm_aas.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/public/assets/logo-blue.svg");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/public/assets/logo-white.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/src/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ServicesDropdown"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/src/components/Header/ServicesDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/src/components/Header/SideMenu/SideMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/src/components/NavLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/home/runner/work/mdi-portal-frontend/mdi-portal-frontend/src/components/Providers/SessionProvider.tsx");
